var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "form-block" }, [
              _c(
                "div",
                { staticClass: "source" },
                [
                  _vm.quantityToBeDispatched > 0
                    ? _c(
                        "el-form",
                        {
                          ref: "formData",
                          staticClass: "demo-form",
                          attrs: {
                            model: _vm.formData,
                            rules: _vm.rules,
                            "label-position": "left"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Qty To Dispatch",
                                prop: "quantity"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  disabled: "",
                                  name: "quantity",
                                  precision: 2,
                                  step: 1,
                                  max: _vm.quantityToBeDispatched
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.toggleSelection()
                                  }
                                },
                                model: {
                                  value: _vm.formData.quantity,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "quantity", $$v)
                                  },
                                  expression: "formData.quantity"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Dispatch Date",
                                prop: "dispatchDate"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  name: "dispatchDate",
                                  type: "date",
                                  placeholder: "Pick a day",
                                  format: "dd-MM-yyyy",
                                  "picker-options": {
                                    disabledDate: _vm.isDateAllowed
                                  }
                                },
                                model: {
                                  value: _vm.formData.dispatchDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "dispatchDate", $$v)
                                  },
                                  expression: "formData.dispatchDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "TransportBilty",
                                prop: "transportBilty"
                              }
                            },
                            [
                              _c("file-upload", {
                                attrs: {
                                  "file-list": _vm.transportBilty,
                                  "list-type": "text",
                                  url: "/orders/upload"
                                },
                                on: {
                                  change: function($event) {
                                    _vm.formData.transportBilty =
                                      $event.response
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Invoice Attachment",
                                prop: "invoiceAttachment"
                              }
                            },
                            [
                              _c("file-upload", {
                                attrs: {
                                  "file-list": _vm.invoiceAttachment,
                                  "list-type": "text",
                                  url: "/orders/upload"
                                },
                                on: {
                                  change: function($event) {
                                    _vm.formData.invoiceAttachment =
                                      $event.response
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Other Attachment",
                                prop: "otherAttachment"
                              }
                            },
                            [
                              _c("file-upload", {
                                attrs: {
                                  "file-list": _vm.otherAttachment,
                                  "list-type": "text",
                                  url: "/orders/upload"
                                },
                                on: {
                                  change: function($event) {
                                    _vm.formData.otherAttachment =
                                      $event.response
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Comments", prop: "comments" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  name: "comments",
                                  placeholder: "Comments"
                                },
                                model: {
                                  value: _vm.formData.comments,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "comments", $$v)
                                  },
                                  expression: "formData.comments"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading,
                                      expression: "loading"
                                    },
                                    { name: "waves", rawName: "v-waves" }
                                  ],
                                  attrs: { type: "success" },
                                  on: { click: _vm.submitForm }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("global.form.save")) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "10px" }, attrs: { span: 10 } },
            [
              _c(
                "div",
                { staticClass: "form-block" },
                [
                  _vm.order
                    ? _c("el-card", { staticClass: "box-card" }, [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header"
                          },
                          [_c("span", [_vm._v("Order Details")])]
                        ),
                        _c("div", { staticClass: "text item" }, [
                          _vm._v(" ID: " + _vm._s(_vm.order.id) + " ")
                        ]),
                        _c("div", { staticClass: "text item" }, [
                          _vm._v(
                            " Reference: " +
                              _vm._s(_vm.order.orderReference) +
                              " "
                          )
                        ]),
                        _c("div", { staticClass: "text item" }, [
                          _vm._v(
                            " Qty Ordered: " +
                              _vm._s(_vm.order.totalQuantity) +
                              " "
                          )
                        ]),
                        _c("div", { staticClass: "text item" }, [
                          _vm._v(
                            " Qty Dispatched: " +
                              _vm._s(_vm.order.totalQuantityDispatched) +
                              " "
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _vm._v("Quantity to split: "),
          _c("inr", { attrs: { number: _vm.quantityToSettle } })
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.ordersProdiuctListLoading,
              expression: "ordersProdiuctListLoading"
            }
          ],
          key: _vm.tableKey,
          ref: "orderTable",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.ordersProductList, border: "", fit: "" }
        },
        [
          _c("el-table-column", {
            attrs: { width: "100px", align: "left", label: "ID", prop: "id" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.id) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "320px",
              align: "left",
              label: "Product",
              prop: "productId"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.product.name) + " "),
                    _c("br"),
                    scope.row.productsVariation
                      ? _c("small", [
                          _vm._v(
                            " (" +
                              _vm._s(scope.row.productsVariation.name) +
                              ") "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              align: "left",
              label: "Qty Ordered",
              prop: "quantity"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "130px",
              align: "left",
              label: "Qty Dispatched",
              prop: "quantityDispatched"
            }
          }),
          _c("el-table-column", {
            attrs: { width: "150px", align: "left", label: "Outstanding Qty" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          (
                            scope.row.quantity - scope.row.quantityDispatched
                          ).toFixed(2)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "left",
              label: "Adjusted Quantity"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input-number", {
                      attrs: {
                        size: "mini",
                        min: 0,
                        max: _vm.getOutstanding(
                          scope.row.quantity,
                          scope.row.quantityDispatched
                        )
                      },
                      on: { change: _vm.updateQuantity },
                      model: {
                        value: scope.row.outstandingQty,
                        callback: function($$v) {
                          _vm.$set(scope.row, "outstandingQty", $$v)
                        },
                        expression: "scope.row.outstandingQty"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }