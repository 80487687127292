import request from '@/utils/request';
export var fileTransferHeaders = {};
export var defaultOrdersDispatchData = {
  id: 0,
  enabled: true,
  orderId: undefined,
  quantity: 0,
  dispatchDate: null,
  comments: null,
  invoiceAttachment: null,
  transportBilty: null,
  otherAttachment: null,
  ordersProductsDispatch: []
};
export var getOrdersDispatches = function getOrdersDispatches(params) {
  return request({
    url: '/orders-dispatches',
    method: 'get',
    params: params
  });
};
export var createOrdersDispatch = function createOrdersDispatch(data) {
  return request({
    url: '/orders-dispatches',
    method: 'post',
    data: data
  });
};
export var getOrdersDispathById = function getOrdersDispathById(id) {
  return request({
    url: "/orders-dispatches/".concat(id),
    method: 'get'
  });
};
export var updateOrdersDispath = function updateOrdersDispath(id, data) {
  return request({
    url: "/orders-dispatches/".concat(id),
    method: 'patch',
    data: data
  });
};